<template>
    <div v-bind:style="styles">
       <div id="page-content">	
				<!-- <div class="dispflex" style="align-items:baseline">
					<h1 class="hdrtitle ml-2" style="margin-top: 0px;">Fees Management</h1>
					 <button type="button" class="backbtn" @click="previousRoute">
          <i class="fa fa-arrow-left"></i>
        </button>
				</div> -->
        <div class="webNew-dispflex"   >
       
       <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Fees Management
       </h4>
      </div>
			
				<div class="row mt-2">	
					<div class="col-md-12 col-lg-12 col-sm-12">
						<div class="widjet m-2">
							<div class="widjethdr dispflex ">
								<div style="width:auto">
									<span class="iconsect blubg" style="padding-left:12px;"><i class="fas fa-clipboard-check"></i></span>
									<h1>Assign Fees</h1>
								</div>
                
                 <!-- <button style="margin-left: 87%;margin-top: 0%;" class="backbtn" @click="previousRoute">
                <i class="fa fa-arrow-left"></i>
              </button> -->
                <button class="btn gry_bg tooltipt" @click.prevent="previewSelected">
                  <i class="far fa-eye"></i>
                  <span class="tooltiptext">Preview</span>
                </button>
               

							</div>
							
							<div class="row widjetcontent mt-1 p-3">
								
								<div class="col-md-4 col-lg-4 col-sm-12">
									<div class="input-group col-md-6 mt-3 ">						
										<span class="has-float-label">
											 <select
                        @change="getSectionList(selectClass)"
												class="form-control form-input"
												id="acyearid"
												name="acdyr"
                        v-model="selectClass"
											>
												<option :value="null">-- Select --</option>
												<option
												v-for="(item, index) in classList"
												:key="index"
												:value="item._id"
												>
												{{ item.className }}
												</option>
											</select>
											
											<label for="acyearid"
												>Select Class <span class="required">*</span></label
											>
											
										</span>
									</div>
                  
								</div>
                	<div class="col-md-4 col-lg-5 col-sm-12">
									<div class="input-group col-md-6 mt-3 ">						
										<span class="has-float-label">
											 <select
                        @change="getSelectClassStudentList"
												class="form-control form-input"
												id="acyearid"
												name="acdyr"
                        :disabled="!selectClass"
                        v-model="selectSection"
											>
												<option :value="null">-- Select --</option>
												<option
												v-for="(item, index) in sectionList"
												:key="index"
												:value="item._id"
												>
												{{ item.name }}
												</option>
											</select>
											
											<label for="acyearid"
												>Select Section <span class="required">*</span></label
											>
											
										</span>
									</div>
                  
								</div>
							
							</div>
               <div class="p-3 widjetcontent" v-if="selectClass && selectSection">
                <div class="tabs" style="height: 2%;background:none">
                 <input
                  type="radio"
                  name="tabs"
                  id="allstudents"
                  checked="checked"
                />
                <label for="allstudents">Available Students [{{ studentList.length }}]</label>
                 <div class="tab">
                <div class="tbllist mt-1">
                  <div class="restable">
                    <div class="resrow resheader">
                      <div class="cell">
                        <label class="custcheckbox">
                        <input  :disabled="studentList.length == 0" type="checkbox" v-model="selectAllAvailable" @input.prevent="selectAllStudent(selectAllAvailable)" />
                            <span class="checkmarkchk"></span>
                          </label>
                      </div>
                      <div class="cell">Student Name</div>
                     
                    </div>
                    <div class="resrow" v-for="(data, index) in studentList" :key="index">
                      <div class="cell chkbox" data-title="Select">
                        <label class="custcheckbox">
                         <input  v-model="data.isSelected" type="checkbox" style="cursor: allowed;display: list-item" />
                          <span class="checkmarkchk"></span>
                        </label>
                      </div>

                      <div class="cell" data-title="Teacher Name">{{ data.firstName }}</div>
                     
                    </div>
                    
                  </div>
                </div>
                	<div class="widjetfooter text-center dkicon">							
									<!-- <button :disabled="studentList.length == 0" type="submit" class="btn btnsml" @click.prevent="setAllSelect">
									<span>Select All</span>
                  </button> -->
                   <button
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                     :disabled="studentList.length == 0" @click.prevent="updateAsignFee"
                  >
                      <span> Assign</span>
                  </button>
                   
									<button @click="previousRoute" type="button" class="btn btncl clsmdl">
									   <span>Cancel</span></button>
								</div>
                  </div>
                  <input
                  type="radio"
                  name="tabs"
                  id="asigend"
                />
                <label for="asigend">Fees Assigned Students  [{{ assignedStudents.length }}]</label>
                 <div class="tab">
                <div class="tbllist mt-1">
                  <div class="restable">
                    <div class="resrow resheader">
                      <div class="cell">
                        <label class="custcheckbox">
                        <input  :disabled="assignedStudents.length == 0" type="checkbox" v-model="selectAllRevoke" @input.prevent="selectAllRevokeStudents(selectAllRevoke)" />
                            <span class="checkmarkchk"></span>
                          </label>
                      </div>
                      <div class="cell">Student Name</div>
                     
                    </div>
                    <div class="resrow" v-for="(data, index) in assignedStudents" :key="index">
                      <div class="cell chkbox" data-title="Select">
                        <label class="custcheckbox">
                         <input  v-model="data.isSelected" type="checkbox" style="cursor: allowed;display: list-item" />
                          <span class="checkmarkchk"></span>
                        </label>
                      </div>
                      

                      <div class="cell" data-title="Teacher Name">{{ data.firstName }}</div>
                     
                    </div>
                  </div>
                </div>
                <div class="widjetfooter text-center dkicon">							
									<!-- <button :disabled="studentList.length == 0" type="submit" class="btn btnsml" @click.prevent="setAllSelect">
									<span>Select All</span>
                  </button> -->
                   <button
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                     :disabled="assignedStudents.length == 0" @click.prevent="revokeAsignFee"
                  >
                      <span>Revoke</span>
                  </button>
                   
									<button @click="previousRoute" type="button" class="btn btncl clsmdl">
									   <span>Cancel</span></button>
								</div>
                  </div>
                  <input
                  type="radio"
                  name="tabs"
                  id="paidstudents"
                />
                <label for="paidstudents">Fees Paid Students [{{ paidStudents.length }}]</label>
                 <div class="tab">
                <div class="tbllist mt-1">
                  <div class="restable">
                    <div class="resrow resheader">
                     <div class="cell">Select</div>
                     <div class="cell">Student Name</div>
                     
                    </div>
                    <div class="resrow" v-for="(data, index) in paidStudents" :key="index">
                      <div class="cell chkbox" data-title="Select">
                        <label class="custcheckbox">
                         <input disabled v-model="data.isSelected" type="checkbox" style="cursor: not-allowed;display: list-item" />
                          <span class="checkmarkchk"></span>
                        </label>
                      </div>
                     

                      <div class="cell" data-title="Teacher Name">{{ data.firstName }}</div>
                     
                    </div>
                  </div>
                </div>
                <div class="widjetfooter text-center dkicon">							
							
									<button @click="previousRoute" type="button" class="btn btncl clsmdl">
									   <span>Cancel</span></button>
								</div>
                  </div>
               
                 </div>
                </div>
              
						</div>
						
					</div>
				</div>
			
			</div>
       <b-modal id="showFeestype" style="width: 100%"  no-close-on-backdrop no-close-on-esc>
        <button type="button" class="close"  @click.prevent="closePreview">×</button>
        <div class="contact-form">
          <!-- <span class="close" @click.prevent="closePreview">&times;</span> -->
          <h2>Details</h2>
          <br />
          <div style="height: 350px; overflow-y: auto">
            <div class="restable mt-3" style="border-top: 0">
              <!-- <div class="blutab">Selected Class And Students</div> -->
              <div class="resrow resheader">
                 <div class="cell">S.No</div>
                <div class="cell">class Name</div>
                <!-- <div class="cell">Section</div> -->
                 <div class="cell">No of students fees assigned</div>
                <!-- <div class="cell">students</div> -->
              </div>
              <div class="resrow" v-for="(data, index) in previewList" :key="index" >
                <div class="cell" data-title="Term Name"> {{ index + 1 }}</div>
                <div class="cell" data-title="Invoice Date">{{ data.className }} </div>
                <!-- <div class="cell" data-title="Invoice Date">{{ data.section }} </div> -->
                <div class="cell" data-title="Invoice Date">{{ data.students.length }} </div>
                <!-- <div class="cell" data-title="Invoice Date">{{ getStudents(data.students) }} </div> -->
               
              </div>
              </div>
              
          </div>
        </div>
     </b-modal>    <!-- fee types-->
    </div>
</template>
<script>

import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";

export default {
  name: "vi-adhocFees",
  data() {
    return {
      assignedStudents: [],
      paidStudents: [],
      classList: [],
      studentList: [],
      selectClass: null,
      selectSection: null,
      previewList: [],
      sectionList: [],
      selectAllAvailable: false,
      selectAllRevoke: false,
      allPssignedStudents: [],
      allPaidStudents: [],
      allStudentList: [],
      commonStudentList: []

    };
  },
  created() {
	  this.getClassList();
  },
  computed: {
       styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
      }
  },
  methods: {
    selectAllStudent(value){
      if(value){
      for(let x of this.studentList){
          x.isSelected = false;
      }
      
      }else{
        for(let x of this.studentList){
          x.isSelected = true;
        }
      }	
    },
    selectAllRevokeStudents(value){
      if(value){
      for(let x of this.assignedStudents){
          x.isSelected = false;
      }
      
      }else{
        for(let x of this.assignedStudents){
          x.isSelected = true;
        }
      }	
    },
    
    previousRoute(){
    let data = localStorage.getItem('previousTab');
      let data2 = localStorage.getItem('activeTab');
     

        if(data2 == '/feesManagement/home'){
          localStorage.setItem("activeTab",'/feesManagement/home');
          localStorage.setItem("previousTab",'/feesManagement/home');
        }else{
           localStorage.setItem("activeTab",data);
          localStorage.setItem("previousTab",data);
        }
     this.$router.go(-1)
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },

	async getClassList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
         this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/class/getAllClasses?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
		    	this.classList = secureUI.secureGet(response.data);

          if(this.classList.length > 0){
            let value = this.classList[0]._id
            this.selectClass = value
            await this.getSectionList(value)
          }
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    setAllSelect(){
      for(let x of this.studentList){
          x.isSelected = true
      }
    },
    closePreview(){
     this.$bvModal.hide("showFeestype");
    },
    async previewSelected(){
     
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
         const response = await ViService.viXGet(`/feesManagement/previewSelected?feeId=${this.$route.params.id}`,userData.token );
     
        if (response.isSuccess) {
		      this.previewList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
         this.$bvModal.show("showFeestype");
      }
    
    },
    getStudents(studentsList){
      if(studentsList.length > 0 ){
         return studentsList.map( x => x.firstName )
      }else{
        return ''
      }
    
    },
    async getSectionList(classId){
      if(classId){

       this.sectionList = []
       this.studentList = []
       this.selectSection = null

       let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(`/contact/getSectionList?class=${classId}`,userData.token);
        if (response.isSuccess) {
          this.sectionList = secureUI.secureGet(response.data);
          
          if(this.sectionList.length > 0){
            let value = this.sectionList[0]._id
            this.selectSection = value
            await this.getSelectClassStudentList()
          }

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      }
    },
    async getSelectClassStudentList() {
     if(this.selectSection){

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
         const response = await ViService.viXGet(`/feesManagement/getSelectClassStudentList?feeId=${this.$route.params.id}&classId=${this.selectClass}&sectionId=${this.selectSection}`,userData.token );
     
        if (response.isSuccess) {
		      const allStudents = secureUI.secureGet(response.data);
          
          if(allStudents.length > 0){
                this.studentList = allStudents.filter( x => x.isSelected == false)
                this.assignedStudents = allStudents.filter( x => x.isSelected == true && x.studentPaid == false)
                this.paidStudents = allStudents.filter( x => x.studentPaid)
                this.allPssignedStudents = this.paidStudents
                this.allPaidStudents = this.paidStudents
                this.allStudentList = this.studentList
                this.commonStudentList = allStudents
               
                if(this.assignedStudents.length > 0){
                  this.selectAllRevoke = true
                }else{
                  this.selectAllRevoke = false
                }
          }
         

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    }
    },

     async updateAsignFee() {
      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll("crtfeeTypeValidate").then((result) => {
        isFormValid = result;
      });

      if(isFormValid){
        let userData = secureUI.sessionGet("user");

        if(!userData){
          this.$toasted.error("Please login and do the action");
        } else {

          let selectedStudents = this.studentList.filter( x => x.isSelected == true );
         

          if(selectedStudents.length > 0){
          selectedStudents = selectedStudents.concat(this.assignedStudents)
          selectedStudents = selectedStudents.concat(this.paidStudents)

          const selectedId = selectedStudents.map( x => x._id )

          let bodyData = {
           _id: this.$route.params.id,
           class: this.selectClass,
           section: this.selectSection,
           studentIds: selectedId,
           assignStudents: this.commonStudentList
          }

          const response = await ViService.viXPost("/feesManagement/updateAssignAdhocFee",bodyData,userData.token );

          if (response.isSuccess) {
            this.$toasted.success('Adhoc Fee has successfully been updated. Please check the student Fee Payment Tab');
            this.commonStudentList = [],
            this.selectClass = null,
            this.selectSection = null,
            this.previewList = [],
            this.allPssignedStudents = [],
            this.allPaidStudents = [],
            this.allStudentList = [],
            this.commonStudentList = [],
            this.assignedStudents = [],
            this.paidStudents = [],
            this.selectAllAvailable = false
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }else {
            this.$toasted.error('Please select atleast one student...');
          }
        }
      } else {
        this.$toasted.error(message);
      }
     },
      async revokeAsignFee() {
      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll("crtfeeTypeValidate").then((result) => {
        isFormValid = result;
      });

      if(isFormValid){
        let userData = secureUI.sessionGet("user");

        if(!userData){
          this.$toasted.error("Please login and do the action");
        } else {

          let selectedStudents = this.assignedStudents.filter( x => x.isSelected == false );
         

          if(selectedStudents.length > 0){

            let students = this.assignedStudents.filter( x => x.isSelected == true )
            const selectedId = students.map( x => x._id )


          let bodyData = {
           _id: this.$route.params.id,
           class: this.selectClass,
           section: this.selectSection,
           studentIds: selectedId || [],
           assignStudents: this.commonStudentList
          }

          const response = await ViService.viXPost("/feesManagement/updateAssignAdhocFee",bodyData,userData.token );

          if (response.isSuccess) {
            this.$toasted.success('Adhoc Fee is revoked successfully');
            this.commonStudentList = [],
            this.selectClass = null,
            this.selectSection = null,
            this.previewList = [],
            this.allPssignedStudents = [],
            this.allPaidStudents = [],
            this.allStudentList = [],
            this.commonStudentList = [],
            this.assignedStudents = [],
            this.paidStudents = []
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }else {
            this.$toasted.error('Please UnSelect Students...');
        }

        }
      } else {
        this.$toasted.error(message);
      }
     },
  },
};
</script>